/**
 * Component to render rich text content using Draft.js.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.text] - The raw Draft.js content state as a JSON string.
 * @returns {JSX.Element} The rendered rich text content.
 */
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import './rich-text.scss'

function RichText({ text }: { text?: string }): JSX.Element {
  if (typeof window === `undefined` || !text) {
    return <></>
  }

  const draftState = EditorState.createWithContent(
    convertFromRaw(JSON.parse(text))
  )

  const textHtml = draftToHtml(convertToRaw(draftState.getCurrentContent()))

  return (
    <div
      className="rich-text-container"
      dangerouslySetInnerHTML={{ __html: textHtml }}
    />
  )
}

export default RichText
